'use client'

import { useState } from 'react'
import { Drawer, Button, Badge } from '@material-tailwind/react'
import Icon from '@/components/Icon'
import { useDictionary } from '@/providers'
import { Filters } from '@/types'
import filterGenerators from '../generators'
import SelectedFilters from '@/components/Filters/components/SelectedFilters'

type Props = {
  components: Array<Filters.FilterItem>
  onFilter: Filters.onFilter
  filters: Record<string, any>
  filterCount: number
  onApplyFilters?: Filters.onApplyFilters
  onResetFilter?: Filters.onResetFilter
  onResetAllFilter?: Filters.onResetAllFilter
  onShowMoreFilters?: Filters.onShowMoreFilters
  className?: string
  group?: Filters.Group
}

export function Mobile({
  components,
  onFilter,
  filters,
  filterCount,
  onResetFilter = () => {},
  onApplyFilters = () => {},
  onResetAllFilter = () => {},
  onShowMoreFilters = () => {},
  className = '',
  group,
}: Props) {
  const d = useDictionary('filters')

  const [mobilePopupOpen, setMobilePopupOpen] = useState(false)
  // using for offCanvas content hide after close canvas
  const [showFilters, setShowFilters] = useState(true)

  const toggleOffCanvas = () => {
    setShowFilters(true)

    if (mobilePopupOpen) {
      setMobilePopupOpen(false)
      setTimeout(() => setShowFilters(false), 500)
      return
    } else {
      setTimeout(() => setMobilePopupOpen(true), 500)
    }
  }

  return (
    <>
      <Badge
        content={filterCount}
        invisible={!filterCount}
        className='min-h-5 min-w-5 bg-primary text-white lg:hidden'
      >
        <Button
          aria-label={d('filter_mobile_btn_aria_label')}
          size='sm'
          color='black'
          variant='outlined'
          onClick={toggleOffCanvas}
          className={`font-lg flex h-9 items-center justify-center gap-x-1 border border-solid border-light-gray-color px-1 py-1 font-normal capitalize leading-8 md:gap-x-2 md:px-2 md:py-2 ${className}`}
        >
          <span className='leading-tight'>{d('filter_mobile_btn')}</span>
          <Icon className='block' size='13px' icon='filter' />
        </Button>
      </Badge>
      {showFilters && (
        <Drawer
          open={mobilePopupOpen}
          onClose={toggleOffCanvas}
          className='pb-20'
          overlayProps={{
            className: '!fixed',
          }}
        >
          <div className='max-h-full overflow-y-auto p-2'>
            <Button
              className='mb-2 flex w-full items-center justify-start gap-4 border-0 border-b px-0 py-3 text-lg font-normal capitalize !shadow-none'
              onClick={toggleOffCanvas}
              aria-label={d('filter_mobile_btn_close_aria_label')}
            >
              <Icon
                className='block text-black'
                size='15px'
                icon='arrow-left'
              />
              {d('filter_mobile_title')}
            </Button>

            {filters && Object.keys(filters).length > 0 && (
              <SelectedFilters
                onResetAllFilter={onResetAllFilter}
                onResetFilter={onResetFilter}
                selectedItems={filters}
                onFilter={onFilter}
                mobile
                group={group}
              />
            )}

            {components?.map((component, key) => {
              const Generator = filterGenerators[component.type]
              if (!Generator) {
                return null
              }
              return (
                <Generator
                  key={key}
                  component={component}
                  onFilter={onFilter}
                  filter={
                    filters?.hasOwnProperty(component?.name)
                      ? filters[component?.name]
                      : {}
                  }
                  onResetFilter={onResetFilter}
                  onShowMoreFilters={onShowMoreFilters}
                  group={group}
                  mobile
                />
              )
            })}
          </div>

          <div className='absolute bottom-0 left-0 right-0 z-50 flex w-full items-center gap-2 bg-white px-4 py-4'>
            <Button
              color='white'
              variant='filled'
              onClick={() => {
                onResetAllFilter(group)
                setTimeout(() => setMobilePopupOpen(false), 100)
              }}
              className='shrink-0 border font-normal capitalize !shadow-none'
              aria-label={d('filter_mobile_btn_reset_all_aria_label')}
            >
              {d('filter_mobile_btn_reset_all')}
            </Button>
            <Button
              color='black'
              variant='filled'
              className='shrink-1 w-full border border-black font-normal capitalize !shadow-none'
              onClick={() => {
                onApplyFilters()
                setTimeout(() => setMobilePopupOpen(false), 100)
              }}
              aria-label={d('filter_mobile_btn_show_results_aria_label')}
            >
              {d('filter_mobile_btn_show_results_prefix')} {filterCount}{' '}
              {d('filter_mobile_btn_show_results_suffix')}
            </Button>
          </div>
        </Drawer>
      )}
    </>
  )
}
