'use client'

import { useRef, useState, useCallback, useTransition } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { Page } from '@/types'
import {
  changeUrlParams,
  transformFiltersToFetchParams,
  isFilters,
} from './utils'
import {
  FilterCount,
  FilterValue,
  Group,
  GroupPositions,
} from '@/types/Filters'
import { fetchFacet } from '@/actions/facet'

interface Filters {
  [key: string]: FilterValue | Filters
}

export function useFilters(
  initialState: Filters = {},
  componentId: number,
  initialPages: Page.Pages,
  initialGroups?: string[] | undefined,
  initialGroupsPositions?: GroupPositions
) {
  const [filters, setFilters] = useState<Filters>(initialState)
  const prevFiltersRef = useRef<Filters>(initialState)
  const [groupPositions, setGroupPositions] = useState<GroupPositions>(
    initialGroupsPositions || {}
  )
  const [filterCount, setFilterCount] = useState<FilterCount>({
    total: 0,
    groups: {},
    other: 0,
  })
  const [loading, startTransition] = useTransition()

  const router = useRouter()
  const pathname = usePathname()

  const updateState = useCallback(
    (
      updateValue: Filters,
      changeUrl: boolean,
      updateGroupName?: string,
      updateGroupPositions?: GroupPositions
    ) => {
      setFilters((prevState) => {
        let newFilters = { ...prevState }

        if (updateGroupName) {
          const groupFilters = isFilters(prevState[updateGroupName])
            ? (prevState[updateGroupName] as Filters)
            : {}
          const updatedGroupFilters = {
            ...groupFilters,
            ...updateValue,
          } as Filters
          newFilters = {
            ...prevState,
            ...{ [updateGroupName]: updatedGroupFilters },
          }
        } else {
          newFilters = { ...prevState, ...updateValue }
        }

        getSelectedItemsCount(newFilters)

        if (!changeUrl) {
          setTimeout(() => {
            changeUrlParams(
              prevFiltersRef.current, // Use ref to get latest filters
              router,
              startTransition,
              pathname,
              initialPages,
              initialGroups,
              updateGroupPositions
            )
          }, 0)
        }

        prevFiltersRef.current = newFilters
        return newFilters
      })
      setGroupPositions((prevState) => ({
        ...prevState,
        ...updateGroupPositions,
      }))
    },
    [router, pathname, initialPages, initialGroups]
  )

  const onFilter = useCallback(
    (
      name: string,
      updateValue: FilterValue,
      changeUrl: boolean = false,
      group?: Group
    ) => {
      if (group && group.name) {
        const positions = { [group.name]: group.position || 0 }
        updateState({ [name]: updateValue }, changeUrl, group.name, positions)
      } else {
        updateState({ [name]: updateValue }, changeUrl)
      }
    },
    [filters, updateState, isFilters]
  )

  const resetFilter = useCallback(
    (name: string, changeUrl: boolean = true, group?: Group) => {
      const newFilters = { ...filters }
      const positions = { ...groupPositions }
      if (group && group.name && isFilters(newFilters[group.name])) {
        const groupFilters = { ...newFilters[group.name] } as Filters
        delete groupFilters[name]

        if (Object.keys(groupFilters).length === 0) {
          delete newFilters[group.name]
          delete positions[group.name]
        } else {
          newFilters[group.name] = groupFilters
        }
      } else {
        delete newFilters[name]
      }

      setFilters(newFilters)
      setGroupPositions(positions)
      getSelectedItemsCount(newFilters)
      if (changeUrl) {
        setTimeout(() => {
          changeUrlParams(
            newFilters,
            router,
            startTransition,
            pathname,
            initialPages,
            initialGroups,
            positions
          )
        }, 0)
      }
    },
    [filters, router, pathname, initialPages, initialGroups, changeUrlParams]
  )

  const resetAllFilters = useCallback(
    (group?: Group) => {
      let newFilters
      let positions: GroupPositions
      if (group && group.name) {
        newFilters = { ...filters }
        positions = { ...groupPositions }

        delete newFilters[group.name]
        delete positions[group.name]

        setGroupPositions(positions)
      } else {
        newFilters = {}
      }
      setFilters(newFilters)
      getSelectedItemsCount(newFilters)
      setTimeout(() => {
        changeUrlParams(
          newFilters,
          router,
          startTransition,
          pathname,
          initialPages,
          initialGroups,
          positions
        )
      }, 0)
    },
    [
      filters,
      initialState,
      router,
      pathname,
      initialPages,
      initialGroups,
      changeUrlParams,
    ]
  )

  const showMoreFilters = useCallback(
    async (componentName: string) => {
      const getParams = transformFiltersToFetchParams(
        filters,
        componentId,
        componentName
      )

      try {
        const { data } = await fetchFacet(getParams)

        return data
      } catch (e) {
        console.error('Error - Facet:', e)
        return
      }
    },
    [filters, componentId]
  )

  const applyFilters = useCallback(() => {
    setTimeout(() => {
      changeUrlParams(
        filters,
        router,
        startTransition,
        pathname,
        initialPages,
        initialGroups
      )
    }, 0)
  }, [filters, router, pathname, initialPages, initialGroups, changeUrlParams])

  const getSelectedItemsCount = useCallback(
    (filters: Filters) => {
      let totalCount = 0
      let otherCount = 0
      const groupCounts: { [key: string]: number } = {}

      const countItems = (filters: Filters, isGroup = false): number => {
        return Object.entries(filters).reduce((count, [key, filterValue]) => {
          if (
            typeof filterValue === 'object' &&
            !Array.isArray(filterValue) &&
            'selected' in filterValue
          ) {
            // This is a filter, not a nested group
            if (Array.isArray(filterValue.selected)) {
              count += filterValue.selected.length
            } else if (filterValue.selected) {
              count += 1
            }
            if (!isGroup) {
              otherCount += count
            }
          } else if (isFilters(filterValue)) {
            // This is a nested group
            groupCounts[key] = countItems(filterValue as Filters, true)
          }
          return count
        }, 0)
      }

      totalCount = countItems(filters)
      setFilterCount({
        total: totalCount,
        groups: groupCounts,
        other: otherCount,
      })
    },
    [setFilterCount, isFilters]
  )

  return {
    filters,
    filterCount,
    onFilter,
    resetFilter,
    applyFilters,
    resetAllFilters,
    showMoreFilters,
    loading,
  }
}
