import { Filters as Facets } from '@/types'
import { usePage, useDictionary } from '@/providers'
import {
  CombinationFilter as Filter,
  generateFilterInitialStateByGroups,
  useFilters,
} from '@/components/Filters'
import Loader from '@/components/Loader'

type Props = {
  componentId: number
  combination: string
  combinationLevel2: string
  facets: Facets.Facets
}

export default function CombinationFilter({
  componentId,
  combination,
  combinationLevel2,
  facets,
}: Props) {
  const d = useDictionary('combination_listing')
  const { pages } = usePage()

  const initialFilterState = generateFilterInitialStateByGroups({
    [combination]: facets?.level1,
    [combinationLevel2]: facets?.level2,
  })

  const {
    filters,
    onFilter,
    resetFilter,
    resetAllFilters,
    applyFilters,
    filterCount,
    showMoreFilters,
    loading,
  } = useFilters(
    initialFilterState,
    componentId,
    pages,
    [combination, combinationLevel2],
    {
      [combination]: 0,
      [combinationLevel2]: 1,
    }
  )

  return (
    <section className='container mb-8 overflow-hidden'>
      <Filter
        components={[
          {
            title: d('filter_group.level_1_title'),
            filters: facets?.level1,
            group: {
              name: combination,
              position: 0,
            },
          },
          {
            title: d('filter_group.level_2_title'),
            filters: facets?.level2,
            group: {
              name: combinationLevel2,
              position: 1,
            },
          },
        ]}
        filters={filters}
        filterCount={filterCount}
        onFilter={onFilter}
        onResetFilter={resetFilter}
        onResetAllFilter={resetAllFilters}
        onApplyFilters={applyFilters}
        onShowMoreFilters={showMoreFilters}
      />
      <Loader
        loading={loading}
        className='fixed z-[99999] bg-transparent backdrop-blur-sm'
        iconClassName='!fill-black'
      />
    </section>
  )
}
